import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';
import { linkObsImage } from '../../contentData/image-obs-link';

import ApplianceContentBox from './appliance-content-box';
import { daikinMaxInverter, mitsubishiFlatDesign, tclNewMiracle, toshibaTopload } from './content-data-sku';
import FAQBoxContent from './faq-box-content';
import BoxDesktop from '../commons/ui/box-desktop';
import { serviceContent } from '../../contentData/service';
import ButtonContent from './button-content';
import { TypeScreen } from '../../utils/enum/responsive-enum';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import CarousalImage from '../commons/ui/carousal-image';

export default function ServiceContentDeskTop() {
  const banners: string[] = linkObsImage.service.banners.desktop;

  const { state } = useLocation();
  useEffect(() => {
    if (state) {
      let element: any = '';
      if (state.faq) {
        element = document.getElementById('box-faq');
      }
      element = element.getBoundingClientRect();
      const heightBox = element.top + window.scrollY || window.innerHeight;
      window.scrollTo({ top: heightBox, behavior: 'smooth' });
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [state]);

  return (
    <>
      <BoxDesktop>
        <CarousalImage urlImage={banners} height="750px" backgroundSize="contain" />
        <Box>
          <Typography
            component="div"
            variant="subtitle1"
            align="center"
            sx={{ fontWeight: 500, fontSize: 24, color: '#433C3C' }}
            mt={2}>
            {serviceContent.howGood.title}
          </Typography>
          <Typography
            component="div"
            variant="subtitle1"
            align="center"
            sx={{ fontWeight: 400, fontSize: 16, color: '#433C3C' }}>
            {serviceContent.howGood.subTitle}
          </Typography>
          <Grid container>
            <Grid xs={3} justifyContent={'center'} display={'grid'} pl={2} pr={2} mt={2}>
              <img
                src={linkObsImage.service.howgood1}
                width={'100%'}
                style={{ maxWidth: '150px', maxHeight: '150px' }}
              />
            </Grid>
            <Grid xs={3} pl={2} pr={2} mt={2} justifyContent={'center'} display={'grid'}>
              <img
                src={linkObsImage.service.howgood2}
                width={'100%'}
                style={{ maxWidth: '180px', maxHeight: '150px' }}
              />
            </Grid>
            <Grid xs={3} pl={2} pr={2} mt={2} justifyContent={'center'} display={'grid'}>
              <img
                src={linkObsImage.service.howgood3}
                width={'100%'}
                style={{ maxWidth: '150px', maxHeight: '140px' }}
              />
            </Grid>
            <Grid xs={3} pl={2} pr={2} mt={2} justifyContent={'center'} display={'grid'}>
              <img
                src={linkObsImage.service.howgood4}
                width={'100%'}
                style={{ maxWidth: '145px', maxHeight: '140px' }}
              />
            </Grid>

            <Grid xs={3} justifyContent={'center'} display={'grid'} pl={2} pr={2} mt={2}>
              <Typography
                component="div"
                variant="body2"
                align="center"
                sx={{ fontWeight: 400, fontSize: 12, color: '#3D433F', whiteSpace: 'pre-line' }}>
                {serviceContent.howGood.text1}
              </Typography>
            </Grid>
            <Grid xs={3} pl={2} pr={2} mt={2} justifyContent={'center'} display={'grid'}>
              <Typography
                component="div"
                variant="body2"
                align="center"
                sx={{ fontWeight: 400, fontSize: 12, color: '#3D433F', whiteSpace: 'pre-line' }}>
                {serviceContent.howGood.text2}
              </Typography>
            </Grid>
            <Grid xs={3} pl={2} pr={2} mt={2} justifyContent={'center'} display={'grid'}>
              <Typography
                component="div"
                variant="body2"
                align="center"
                sx={{ fontWeight: 400, fontSize: 12, color: '#3D433F', whiteSpace: 'pre-line' }}>
                {serviceContent.howGood.text3}
              </Typography>
            </Grid>
            <Grid xs={3} pl={2} pr={2} mt={2} justifyContent={'center'} display={'grid'}>
              <Typography
                component="div"
                variant="body2"
                align="center"
                sx={{ fontWeight: 400, fontSize: 12, color: '#3D433F', whiteSpace: 'pre-line' }}>
                {serviceContent.howGood.text4}
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{ mt: 2, mb: 2, border: '1px solid #BDBDBD' }} />
          <Box sx={{ backgroundColor: '#FFFEF7' }}>
            <Grid container>
              <Grid xs={12} justifyContent={'center'} display={'flex'} pb={3}>
                <img
                  src={linkObsImage.service.mobiles.phoneRecommend}
                  width={'100%'}
                  style={{
                    maxWidth: '650px',
                    maxHeight: '628px',
                  }}
                />
              </Grid>
              <Grid xs={12}>
                <Box mb={6}>
                  <ButtonContent type={TypeScreen.DESKTOP} />
                </Box>
              </Grid>
              <Grid xs={6} justifyContent={'center'} display={'flex'} pb={3}>
                <img
                  src={linkObsImage.service.mobiles.phoneWebSSA05s}
                  width={'100%'}
                  style={{
                    maxWidth: '450px',
                    maxHeight: '428px',
                  }}
                />
              </Grid>
              <Grid xs={6} justifyContent={'center'} display={'flex'} pb={3}>
                <img
                  src={linkObsImage.service.mobiles.phoneWebOPPOA3}
                  width={'100%'}
                  style={{
                    maxWidth: '450px',
                    maxHeight: '428px',
                  }}
                />
              </Grid>
              <Grid xs={6} justifyContent={'center'} display={'flex'} pb={3}>
                <img
                  src={linkObsImage.service.mobiles.phoneWebOPPOA3x}
                  width={'100%'}
                  style={{
                    maxWidth: '450px',
                    maxHeight: '428px',
                  }}
                />
              </Grid>
              <Grid xs={6} justifyContent={'center'} display={'flex'} pb={3}>
                <img
                  src={linkObsImage.service.mobiles.phoneWebOPPOA3Pro5G}
                  width={'100%'}
                  style={{
                    maxWidth: '450px',
                    maxHeight: '428px',
                  }}
                />
              </Grid>
              <Grid xs={6} justifyContent={'center'} display={'flex'} pb={3}>
                <img
                  src={linkObsImage.service.mobiles.phoneWebVIVOY19s}
                  width={'100%'}
                  style={{
                    maxWidth: '450px',
                    maxHeight: '428px',
                  }}
                />
              </Grid>
              <Grid xs={6} justifyContent={'center'} display={'flex'} pb={3}>
                <img
                  src={linkObsImage.service.mobiles.phoneWeb}
                  width={'100%'}
                  style={{
                    maxWidth: '450px',
                    maxHeight: '428px',
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box>
          <Typography
            component="div"
            variant="subtitle1"
            sx={{
              fontWeight: 400,
              fontSize: 12,
              whiteSpace: 'pre-line',
              backgroundColor: '#085103',
              color: '#ffffff',
              padding: '20px',
            }}>
            {serviceContent.remark}
          </Typography>
        </Box>
        <Box mt={12}>
          <ApplianceContentBox skuContent={daikinMaxInverter} />
        </Box>
        <Box mt={12}>
          <ApplianceContentBox skuContent={tclNewMiracle} />
        </Box>

        <Box mt={12}>
          <ApplianceContentBox skuContent={toshibaTopload} />
        </Box>

        <Box mt={12}>
          <ApplianceContentBox skuContent={mitsubishiFlatDesign} />
        </Box>
      </BoxDesktop>
      <Box
        id="box-faq"
        sx={{
          p: 12,
          mt: '-10em',
        }}>
        <FAQBoxContent />
      </Box>
    </>
  );
}
