import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

export interface Props {
  urlImage: string[];
  height?: string;
  backgroundSize?: string;
}

const properties = {
  prevArrow: (
    <IconButton
      sx={{
        ml: -6,
        '& .MuiSvgIcon-root': {
          height: '1.1em',
          paddingLeft: '5px',
        },
      }}>
      <ArrowBackIos sx={{ fontSize: 50, color: '#263238' }} />
    </IconButton>
  ),
  nextArrow: (
    <IconButton sx={{ mr: -7.5 }}>
      <ArrowForwardIos sx={{ fontSize: 50, color: '#263238' }} />
    </IconButton>
  ),
};

export default function CarousalImage(props: Props) {
  const carousalImageStyle = {
    display: 'flex',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: props.height || '350px',
    backgroundSize: props.backgroundSize || 'cover',
  };
  return (
    <>
      <Box style={{ minWidth: 650 }}>
        <Slide {...properties}>
          {props.urlImage.map((slideImage, index) => (
            <Box
              id={`id-${index}`}
              style={{
                backgroundImage: `url(${slideImage})`,
                ...carousalImageStyle,
              }}></Box>
          ))}
        </Slide>
      </Box>
    </>
  );
}
